import './search-form.scss';

import {postAjax, getUrlParameter, scrollToElement} from '../helpers/helpers';

export class SearchForm {
    constructor(target) {
        this.classes = {
            formWrapClass: 'search-wrap',
            formClass: 'search-form__form',
            inputClass: 'search-form__input',
            resultsClass: 'search-form__results',
            loaderClass: 'search-form__loader',
            btnOpenClass: 'btn-open-search',
            openClass: 'is-open',
        };

        this.element = $(target);
        this.formWrap = $('.' + this.classes.formWrapClass);
        this.input = $('.' + this.classes.inputClass);
        this.form = $('.' + this.classes.formClass);
        this.results = $('.' + this.classes.resultsClass);
        this.loader = $('.' + this.classes.loaderClass);
        this.btnOpen = $('.' + this.classes.btnOpenClass);

        this.init();
    }

    init() {
        var that = this;
        this.input.on('keyup', this.searchHandler.bind(this));
        this.btnOpen.on('click', this.openCloseMobile.bind(this));

        if (getUrlParameter('searchItem')) {
            scrollToElement($('.is-highlighted').get(0));
        }

        $(window).on('scrollToDone', this.onScrollToDone.bind(this));

        $(document).on('submit', '.search-form', function(evt) {
            evt.preventDefault();
        });
    }

    searchHandler() {
        const val = this.input.val();

        if (val.length >= 2) {
            this.results.empty();
            this.loader.show();

            postAjax(window.estpress.homeUrl + '?s=' + val, '', (data) => {
                this.loader.hide();
                this.results.empty();
                this.results.html(data);
            });
        } else {
            this.results.empty();
        }
    }

    openCloseMobile() {
        var that = this;
        this.formWrap[this.formWrap.hasClass(this.classes.openClass) ? 'removeClass' : 'addClass'](this.classes.openClass);

        setTimeout(function() {
            if (! that.formWrap.hasClass(that.classes.openClass)) {
                that.input.focus(); // iOS is buggy with .focus() behavior
            }
        }, 255);
    }

    onScrollToDone() {
        var baseUrl = [location.protocol, '//', location.host, location.pathname].join('');

        if (getUrlParameter('searchItem')) {
            window.history.replaceState({}, '', baseUrl);

            setTimeout(function() {
                $('.is-highlighted').removeClass('is-highlighted');
            }, 1500);
        }
    }
}

// Init on document ready
let searchform;
$(function () {
    $('.js-search-form').each(function() {
        searchform = new SearchForm(this);
    });
});
