import "./form.scss";

let errorMessageClass = 'is-invalid';

$(document).on('submit', '.form--login', function(evt) {
    evt.preventDefault();
    var el = $(this);

    el.find('.btn').addClass('is-loading');

    $.ajax({
        url: estpress.ajaxUrl,
        type: 'POST',
        data: el.serialize(),
        success: function(response) {
            el.find('.btn').removeClass('is-loading');
            el.find('.invalid-feedback, .alert').remove();
            el.find('.input-group').removeClass(errorMessageClass);
            el.find('.form-control').removeClass(errorMessageClass);

            if (response.success) {
                el.prepend('<div class="alert alert-success" role="alert">' + response.data + '</div>');
                setTimeout(function() {
                    location.reload();
                }, 1000);
            } else {
                if (Array.isArray(response.data)) {
                    console.log(response.data)
                    el.prepend('<div class="alert alert-danger" role="alert">' + response.data + '</div>');
                }
                $.each(response.data, function(key, value) {
                    var $input = el.find('[name="' + key + '"]');
                    $input.addClass(errorMessageClass);
                    $input.closest('.input-group').addClass(errorMessageClass);
                    $('<div class="invalid-feedback">' + value + '</div>').insertAfter($input);
                });
            }
        }
    });
});
