import "./helpers.scss";

export function disableScroll() {
    if (!$('body').hasClass('is-scroll-disabled')) {
        const curScroll = $(window).scrollTop();

        $('body').addClass('is-scroll-disabled');
        $('.wrapper').css('top', -curScroll);

        $(window).scrollTop(0);
    }
}

export function enableScroll() {
    const bodyScroll = parseInt($('.wrapper').css('top'), 10);

    $('body').removeClass('is-scroll-disabled');
    if (bodyScroll) {
        $(window).scrollTop(-bodyScroll);
        $('.wrapper').css('top', 0);
    }
}

export function postAjax(url, data, success) {
    var params = typeof data == 'string' ? data : Object.keys(data).map(
        function(k) { return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]) }
    ).join('&')

    var xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP')
    xhr.open('POST', url)
    xhr.onreadystatechange = function() {
        if (xhr.readyState > 3 && xhr.status === 200) {
            success(xhr.responseText)
        }
    }

    var isIE11 = !! window.MSInputMethodContext && !! document.documentMode

    if (! isIE11) {
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
    }

    xhr.send(params)
    return xhr
}

/*
 * Smooth scroll
 */
export function scrollToElement(tar) {
    if (typeof tar !== 'undefined') {
        var top = tar.offsetTop - parseFloat(getComputedStyle(tar).marginTop);

        scroll({
            top: top,
            behavior: 'smooth',
        });

        setTimeout(function() {
            $(window).trigger('scrollToDone');
        }, 200);
    }
}

/*
 * Get URL parameter
 */
export function getUrlParameter(name) {
	return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
}

/*
 * Get URL variables
 */
export function getUrlVars() {
    var vars  = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
        vars[key] = value;
    });
    return vars;
}

/**
 * Document ready
 */
$(() => {
    let loadTimer = 0;
    // Calc correct app height for mobile devices
    document.documentElement.style.setProperty('--app-height', window.innerHeight + 'px');
    $(window).on('resize', () => {
        document.documentElement.style.setProperty('--app-height', window.innerHeight + 'px');

        // Remove transitions when resizing
        if (loadTimer) {
            clearTimeout(loadTimer);
            loadTimer = null;
        } else {
            $('body').addClass('preload');
        }

        loadTimer = setTimeout(() => {
            $('body').removeClass('preload');
            loadTimer = null;
        }, 100);
    });
});

/**
 * Onload
 */
 $(window).on('load', () => {
    $('body').removeClass('preload');
    $('body').addClass('is-loaded');
});

