import './assets.scss';

export class Assets {
    constructor(target) {
        this.classes = {
            assetsRowClass: 'assets__row',
            assetsBreadcrumbClass: 'assets__breadcrumb',
        }

        this.element = $(target);
        this.init();
    }

    init() {
        var that = this;
        $(document).on('click', '[data-action="ajaxAssets"]', function(evt) {
            var el = $(this);
            that.onAjaxClick(el, evt);
        });

        window.addEventListener('popstate', function() {
            that.onBack();
        });
    }

    onAjaxClick(el, evt) {
        evt.preventDefault();
        var url = el.attr('href');
        this.doAjax(url);
    }

    doAjax(url) {
        var that = this;
        $('.' + this.classes.assetsRowClass).prepend('<div class="loader"><div></div></div>');

        $.get(url, '', function(data) {
            var $assets     = $('<div>').append(data).find('.' + that.classes.assetsRowClass);
            var $breabrumbs = $('<div>').append(data).find('.' + that.classes.assetsBreadcrumbClass);

            if ($assets) {
                $('.' + that.classes.assetsRowClass).replaceWith($assets);
                history.pushState({}, null, url);
            }

            if ($breabrumbs) {
                $('.' + that.classes.assetsBreadcrumbClass).replaceWith($breabrumbs);
            }
        });
    }

    onBack() {
        var url = [location.protocol, '//', location.host, location.pathname].join('');
        this.doAjax(url);
    }
}

// Init on document ready
let assets;
$(function () {
    assets = new Assets(this);
});
